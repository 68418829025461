// Load Sentry
import * as Sentry from "@sentry/browser";

window.Sentry = Sentry;

import {} from 'jquery';

// Load Popper
require('popper.js');

// Load Bootstrap
require('bootstrap');

// Load jQuery Easing
require('jquery.easing');

// Load Waypoints
require('waypoints/lib/jquery.waypoints');

// Load Stellar.js
require('stellar.js');

import {
  // Load jQuery Fancybox
  Fancybox
} from '@fancyapps/ui';

window.Fancybox = Fancybox;

import Rails from '@rails/ujs';

Rails.start();

import "@hotwired/turbo-rails";

// Load ConcertoEspagne Theme
require('vendor/javascripts/concerto_espagne/concerto_espagne.coffee');

// Load images
require('vendor/images/concerto_espagne/bg/sun.jpg');

require('vendor/images/concerto_espagne/bg/sky.jpg');

require('vendor/images/concerto_espagne/flags/en.png');

require('vendor/images/concerto_espagne/flags/es.png');

require('vendor/images/concerto_espagne/flags/fr.png');

require('vendor/images/concerto_espagne/flags/pt.png');

require('vendor/images/concerto_espagne/contact-photo.jpg');

require('vendor/images/concerto/no_img/website-small.jpg');

require('vendor/images/concerto/logos/spanish-site.png');

import 'styles/concerto_espagne/application.scss';
